<template>
    <div class="wrapper -ser-mentor">

        <section class="encabezado -ser-mentor">
            <h1 class="with-btn">Ser mentor/a</h1>
            <a href="https://gestionmentoring.uc3m.es/login/mentor" target="_blank" class="btn -dark-orange">Regístrate como mentor/a</a>
        </section>

        <section class="tips-container">

            <h2 class="title">¿Por qué ser mentor/a?</h2>

            <div class="container -xs row-start ais">
                <article>
                    <div class="icon">
                        <img src="/img/icon-flexibilidad-dark.svg" alt="Nuevas perspectivas">
                    </div>
                    <h3>Nuevas perspectivas</h3>
                    <p>Te proporciona perspectivas diferentes, ideas y métodos. Es una oportunidad para la reflexión y la mejora.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-networking-dark.svg" alt="Networking">
                    </div>
                    <h3>Networking</h3>
                    <p>Refuerza tu desarrollo profesional y amplía tu red de contactos generando vínculos y relaciones valiosas.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-acompanamiento-dark.svg" alt="PERTENCIA UC3M">
                    </div>
                    <h3>Pertenencia UC3M</h3>
                    <p>Fomenta la cultura de la cooperación y colaboración entre el colectivo UC3M.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-talleres-dark.svg" alt="Crecimiento personal">
                    </div>
                    <h3>Crecimiento personal</h3>
                    <p>Conviértete en agente de cambio y ofrece tu apoyo voluntario en la carrera profesional de otros.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-habilidades-dark.svg" alt="Networking">
                    </div>
                    <h3>Habilidades</h3>
                    <p>La relación de mentoría ofrece la oportunidad para desarrollar o mejorar habilidades interpersonales, de comunicación, estilos de liderazgo y gestión de personas.</p>
                </article>
                <article>
                    <div class="icon">
                        <img src="/img/icon-certificacion-dark.svg" alt="Mentoring">
                    </div>
                    <h3>Certificación</h3>
                    <p>Reconocimiento de participación al completar las sesiones mínimas obligatorias de cada mentoría.</p>
                </article>
            </div>
        </section>

        <section class="banner-compromiso -mentor">
            <div class="container column-center">
                <h2 class="title -white">Compromiso</h2>
                <img src="/img/3x3.svg" alt="3x3x3">
                <div class="tips row-center">
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/3mentees.svg" alt="3 meentes por mentor/a">
                        </div>
                        <h3>3 mentees por mentor/a</h3>
                        <p>Máximo número de mentees por curso académico</p>
                    </article>
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/icon-sesiones-white.svg" alt="3 sesiones">
                        </div>
                        <h3>3 sesiones</h3>
                        <p>Online o Presenciales por cada mentoría/mentee</p>
                    </article>
                    <article class="tip">
                        <div class="icon">
                            <img src="/img/icon-meses-white.svg" alt="3 meses">
                        </div>
                        <h3>3 meses</h3>
                        <p>Duración máxima por mentoría</p>
                    </article>
                </div>
            </div>
        </section>

        <slider-pasos></slider-pasos>

        <section class="requisitos">
            <div class="container">
                <h2 class="title">Requisitos</h2>
                <div class="contenedor-requisitos row-center ais">
                    <article>
                        <div class="imagen -dark"><img src="/img/icon-ribete.svg" alt="Ribete"></div>
                        <h3>Ser egresado/a UC3M</h3>
                    </article>
                    <article>
                        <div class="imagen -dark"><img src="/img/icon-maletin.svg" alt="Maletin"></div>
                        <h3>Experiencia profesional de 3 años o superior</h3>
                    </article>
                </div>
            </div>
        </section>


        <slider-testimonios></slider-testimonios>


        <section class="texto-imagen">

            <div class="container row-start">

                <div class="contenedor-texto column-start">
                    <h2 class="title">Certificación</h2>
                    <p>Podrán obtener este certificado, tras finalizar la mentoría, quienes hayan realizado las tres sesiones mínimas obligatorias de la mentoría y hayan cumplimentado el cuestionario de satisfacción del programa. Reconoce la participación del mentor/a en el mismo y podrá descargarse como un diploma de participación en formato PDF desde el área privado del mentor/a.</p>
                </div>

                <div class="contenedor-imagen">
                    <img src="/img/imagen-certificacion.jpg" alt="Certificación">
                </div>
            </div>

        </section>

        <section class="galeria -dark-orange">
            <div class="container">
                <h2 class="title -white">Networking</h2>
                <p>Para fortalecer los vínculos UC3M y crear un espacio colaborativo intergeneracional donde generar sinergias y potenciar el mayor activo de la Universidad: las personas. 
                    Súmate a nuestra Red de mentores y mentees y forma parte del movimiento #SoyAlumniUC3M.
                    
                </p>
            </div>
            <galeria :id="'networking'"></galeria>

        </section>

        <faqs :show="['mentor']"></faqs>




    </div>
</template>

<script>

    import slidertestimonios from '@/components/slider-testimonios';
    import galeria from '@/components/galeria';
    import faqs from '@/components/faqs';
    import sliderpasos from '@/components/slider-pasos';

    export default {
        name: 'ser-mentor',
        components: {
            'slider-testimonios': slidertestimonios,
            'galeria': galeria,
            'faqs': faqs,
            'slider-pasos': sliderpasos,
        }
    }
</script>