<template>
    <section class="slider-testimonios">


        <div class="container">
            <h2 class="subtitle">Testimonios de mentores</h2>
            <slick
                ref="slick"
                :options="slickOptions">
                <div class="slide">
                    <blockquote><h5>Fue una experiencia muy enriquecedora. Al final, estás contribuyendo a esbozar el inicio de la carrera profesional de otras personas. Confían en ti sus dudas e incertidumbres y juntos trabajáis en encontrar un plan de futuro que encaje con sus motivaciones y aspiraciones, pero además que tenga sentido y encaje en el mundo laboral actual. Es una gran responsabilidad y, por ello, es tan gratificante. Además, como mentor, te ayuda a comprender mejor las preocupaciones y necesidades de los recién graduados en su ‘salto’ al mundo profesional. Lo que considero que será muy importante para atraer el talento del futuro. Sin duda, es una experiencia que se la recomiendo a cualquier persona, de cualquier edad o sector.
                    </h5></blockquote>
                    <div class="persona column-center">
                        <img src="/img/rosa-puentes.jpg" alt="Rosa Puentes">
                        <h3>Rosa Puentes</h3>
                        <p>Experta en Hidrógeno y Calidad de gas</p>
                        <p>ENTSOG - European Network of Transmission System Operators for Gas</p>
                    </div>
                </div>
                <div class="slide">
                    <h5>Formar parte del Programa de Mentoring Alumni UC3M como mentor es una experiencia muy enriquecedora. Poder ayudar a alumnos cercanos a incorporarse al mercado laboral y resolver desde las inquietudes más básicas a alguna más compleja, me aporta mucho valor personalmente. Me hubiera gustado tener ese apoyo cuando yo estaba en su mismo momento.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/sergio-campos.jpg" alt="Sergio Campos">
                        <h3>Sergio Campos García</h3>
                        <p>Manager Transformación Digital</p>
                        <p>BANKIA</p>
                    </div>
                </div>
                <div class="slide">
                    <h5>El Programa de Mentoring ayuda al mentor a hacer un ejercicio de análisis sobre la evolución personal para poder transmitirle al mentee toda la experiencia necesaria para dar el salto al mundo laboral. 100% recomendado.
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/david-rubio.jpg" alt="David Rubio Vidal">
                        <h3>David Rubio Vidal</h3>
                        <p>Senior Software Engineer in Test</p>
                        <p>DAZN</p>
                    </div>
                </div>
                <div class="slide">
                    <h5>Me ha encantado participar en el Programa Mentoring y espero volver a hacerlo en el futuro. Es muy enriquecedor compartir tu experiencia con jóvenes que están empezando en el mundo laboral. Sus preguntas y comentarios son una corriente de aire fresco que te lleva a una realidad diferente a la de tu día a día; te ayudan a pensar en cosas en las que habitualmente no reflexionas. ¡Muy recomendable!
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/raquel-gonzalez.jpg" alt="Raquel González López">
                        <h3>Raquel González López</h3>
                        <p>Responsable de Experiencia de Cliente</p>
                        <p>IBERDROLA, S.A.U. </p>
                    </div>
                </div>
                <div class="slide">
                    <h5>Ha sido un placer para mí poder compartir mi experiencia laboral con una alumna de la UC3M que está interesada en trabajar en Organismos Internacionales. ¡Espero que mis orientaciones le sean útiles y que logre todos sus objetivos profesionales!
                    </h5>
                    <div class="persona column-center">
                        <img src="/img/nidia-calleros.jpg" alt="Nidia Calleros">
                        <h3>Nidia Calleros</h3>
                        <p>Abogada experta en derechos humanos</p>
                        <p>LEECP - Liga Española de la Educación y la Cultura Popular</p>
                    </div>
                </div>
           
            </slick>

        </div>

    </section>
</template>

<script>

import Slick from 'vue-slick';

import 'slick-carousel/slick/slick.css';

    export default {
        name : "slider-empresas",
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    dots : true,
                    arrows : false,
                    adaptiveHeight: false
                },
            }
        },
        components: { Slick },
    }
</script>